// Body
$body-bg: #f8fafc;

// Typography
//$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// オリジナル
$mycolor: #8ec31f;
$mybgcolor: #f3f9e8;
$halfcolor: #c6e18f;
$subcolor: #ffb84d;
$textcolor: #8c8b70;

$mycolor_img: invert(59%) sepia(72%) saturate(457%) hue-rotate(38deg) brightness(101%) contrast(93%);

/* フォント　カスタマイズ */
$font-family-sans-serif: "メイリオ", Meiryo, "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;